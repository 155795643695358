@mixin fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim {
    transition: 0.3s all ease;
}

@mixin step_gradient{
    background: rgba(14,153,72,1);
    background: -moz-linear-gradient(left, rgba(14,153,72,1) 0%, rgba(14,153,72,1) 35%, rgba(226,235,240,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(14,153,72,1)), color-stop(35%, rgba(14,153,72,1)), color-stop(100%, rgba(226,235,240,1)));
    background: -webkit-linear-gradient(left, rgba(14,153,72,1) 0%, rgba(14,153,72,1) 35%, rgba(226,235,240,1) 100%);
    background: -o-linear-gradient(left, rgba(14,153,72,1) 0%, rgba(14,153,72,1) 35%, rgba(226,235,240,1) 100%);
    background: -ms-linear-gradient(left, rgba(14,153,72,1) 0%, rgba(14,153,72,1) 35%, rgba(226,235,240,1) 100%);
    background: linear-gradient(to right, rgba(14,153,72,1) 0%, rgba(14,153,72,1) 35%, rgba(226,235,240,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0e9948', endColorstr='#e2ebf0', GradientType=1 );
}

$special_font:'Poppins', sans-serif;
$main_font:'Open Sans', sans-serif;

$light_grey:#e2ebf0;
$dark_grey:#7c95a6;
$header_color:#00335f;
$font_color:#6e7c87;
$white:#ffffff;
$content_color:#fcfdfe;

$accent_color2:#eef4f7;
$accent_color:#0e9948;

body{
    font-family: $main_font;
    h1,h2,h3,h4,h5,h6,p,ul,ol,a,p{
        margin: 0;
    }
    ul{
        padding: 0;
        list-style: none;
    }
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
    &.scroll_block{
        overflow: hidden;
    }
}
.anim{
	animation: animation 3s infinite alternate;
    position: relative;
    @include anim;
}
@keyframes animation{
    0% {bottom: 0;}
    50% {bottom: 10px;}
    100% {bottom: -10px;}
}

.vertical_align{
    @include fill;
    > div{
        display: table;
        height: 100%;
        width: 100%;
        > div{
            display: table-cell;
            vertical-align: middle;
            height: 100%;
        }
    }
}

.default_font{
    font-size: 14.5px;
    line-height: 20px;
    color: $font_color;
    @media only screen and (max-width: 1500px) {
        font-size: 14px;
        line-height: 30px;
    }
    @media only screen and (max-width: 1200px) {
        font-size: 12px;
        line-height: 28px;
    }
    h1{
        font-size: 70px;
        line-height: 85px;
        @media only screen and (max-width: 1500px) {
            font-size: 50px;
            line-height: 65px;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 30px;
            line-height: 45px;
        }
    }
    h2{
        font-size: 25px;
        line-height: 38px;
    }
    h3{
        font-size: 22px;
        line-height: 32px;
    }
    h4{
        font-size: 20px;
        line-height: 30px;
    }
    h5{
        font-size: 18px;
        line-height: 28px;
    }
    h6{
        font-size: 16px;
        line-height: 26px;
    }
    ul{
        list-style: disc;
        padding-left: 18px;
    }
    ol{
        padding-left: 18px;
    }
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    h1,h2,h3,h4,h5,h6{
        color: $header_color;
    }
    strong{
        font-weight: 600;
    }
}

.more_btn{
    display: inline-block;
    padding: 22px 47px 22px 40px;
    position: relative;
    background:$accent_color;
    color: $white;
    font-size: 12.5px;
    line-height: 12.5px;
    text-decoration: none;
    @include anim;
    font-weight: 600;
    font-family: $special_font;
    border-radius: 50px;
    cursor: pointer;
    @media only screen and (max-width: 1500px) {
        padding: 22px 29px 22px 28px;
    }
    @media only screen and (max-width: 1200px) {
        padding: 22px 30px 22px 30px;
    }
    &:hover{
        color: $white;
    }
    &:after{ 
        @include anim;
        width: 50px;
        height: 21px;
        content: '';
        position: absolute;
        top: 35%;
        right: -25px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 100%;
        background-image: url('./../img/more_arr.png');
        @media only screen and (max-width: 991px) {
            display: none;
        }
    }
    &:hover{
        &:after{
            right: -35px;
        }
    }
}

.to_up{
    margin-top: 20px;
    cursor: pointer;
    display: inline-block;
    padding: 21px 70px 21px 37px;
    position: relative;
    background-color:#e2ebf0;
    color: $font_color;
    font-size: 12.5px;
    line-height: 12.5px;
    text-decoration: none;
    @include anim;
    font-weight: 600;
    font-family: $special_font;
    border-radius: 50px;
    background-position: right 28px bottom;
    background-repeat: no-repeat;
    background-image: url('./../img/to_up_arr.png');
}

.page{
    overflow: hidden;
    position: relative;
    @include anim;
    .slider_bg{
        @include anim;
        height: 100vh;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;
        > div{
            position: absolute;
            top: 0;
            left: 100%;
            height: 100%;
            width: 100%;
            display: none;
            -moz-transform: scale(-1, 1);
            -webkit-transform: scale(-1, 1);
            -o-transform: scale(-1, 1);
            -ms-transform: scale(-1, 1);
            transform: scale(-1, 1);
        }
    }
    &.sl1{
        div.slider_bg{
            background-image: url('./../img/sl1_bg.jpg');
        }
    }
    &.sl2{
        div.slider_bg{
            background-image: url('./../img/sl2_bg.jpg');
        }
    }
    &.sl3{
        div.slider_bg{
            background-image: url('./../img/sl3_bg.jpg');
        }
    }
    &.sl4{
        div.slider_bg{
            background-image: url('./../img/sl4_bg.jpg');
        }
    }
    &.sl5{
        div.slider_bg{
            background-image: url('./../img/sl5_bg.jpg');
        }
    }
}

header.header{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
    div.header_content{
        //margin-bottom: 50px;
        //padding: 28px 0 30px 0;
        margin-bottom: 2.5vw;
        padding: 1vw 0 1vw 0;
        border-bottom: 1px solid #ebebeb;
        @media only screen and (max-width: 1200px) {
            margin-bottom: 30px;
        }
        @media only screen and (max-width: 991px) {
            padding: 15px 0 15px 0;
            margin-bottom: 15px;
        }
        div{
            &.logo_box{
                position: relative;
                img{
                    max-width: 100%;
                    height: auto;
                    @media only screen and (max-width: 1200px) {
                        max-width: 80%;
                        height: auto;
                    }
                    @media only screen and (max-width: 991px) {
                        max-width: 100px;
                        height: auto;
                    }
                }
                .rwd_btn{
                    width: 30px;
                    position: absolute;
                    top: 5px;
                    right: 15px;
                    display: none;
                    @media only screen and (max-width: 991px) {
                        display: block;
                    }
                    > div{
                        height: 3px;
                        margin-bottom: 3px;
                        width: 100%;
                        background: $accent_color;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            &.logotypes_box{
                text-align: right;
                > ul{
                    > li{
                       display: inline-block;
                       vertical-align: top;
                       margin-right: 35px;
                       @media only screen and (max-width: 1200px) {
                        margin-right: 15px;
                        img{
                            max-height: 52px;
                            width: auto;
                        }
                        }
                       &:last-child{
                           margin-right: 0;
                       }
                    }
                }
                
                @media only screen and (max-width: 991px) {
                    text-align: center;
                    padding-top: 15px;
                    img{
                        max-width: auto;
                        height: 31px;
                    }
                }
            }
        }
    }
    div.slider_steps{
        @media only screen and (max-width: 991px) {
            padding-top: 15px;
            display: none;
        }
        .more_btn{
            &:after{
                background-image: url('./../img/dots.png');
                background-size: 100% auto;
                right: -35px;
                top:30%;
            }
            &:hover{
                &:after{
                    right: -40px;
                }
            }
        }
        .col-lg-2, .col-md-3{
            padding: 0;
        }
        div.steps{
            height: 62px;
            width: 100%;
            position: relative;
            p.steps_header{
                display: none;
                text-align: center;
                color: $header_color;
                font-weight: 700;
                font-size: 20px;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
            @media only screen and (max-width: 991px) {
                height: auto;
                p.steps_header{
                    display: block;
                }
            }
            > ul {
                @include fill;
                @media only screen and (max-width: 991px) {
                    position: relative;
                    width: 42px;
                    margin: 0 auto;
                }
                > li{
                    width: 20%;
                    height: 100%;
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        height: 60px;
                    }
                    &.done{
                        div.step{
                            top: 0;
                            border: 3px solid $accent_color;
                            -webkit-box-shadow: 0px 7px 12px -1px rgba(14,153,72,0.29);
                            -moz-box-shadow: 0px 7px 12px -1px rgba(14,153,72,0.29);
                            box-shadow: 0px 7px 12px -1px rgba(14,153,72,0.29);
                        }
                        &:after{
                            background: $accent_color;
                        }
                    }
                    &.on{
                        div.step{
                            -webkit-box-shadow: 0px 7px 12px -1px rgba(14,153,72,0.29);
                            -moz-box-shadow: 0px 7px 12px -1px rgba(14,153,72,0.29);
                            box-shadow: 0px 7px 12px -1px rgba(14,153,72,0.29);
                            top: -20px;
                            font-size: 57px;
                            line-height: 57px;
                            background: $accent_color;
                            color: $white;
                            border:3px solid $accent_color;
                            width: 100px;
                            padding-bottom: 94px;
                            @media only screen and (max-width: 1500px) {
                                width: 80px;
                                padding-bottom: 74px;
                                font-size: 37px;
                                line-height: 37px;
                                top: -10px;
                            }
                            @media only screen and (max-width: 991px) {
                                width: 42px;
                                padding-bottom: 36px;
                                top: 0;
                                font-size: 18px;
                            }
                        }
                        &:after{
                            @include step_gradient;
                        }
                    }
                    &:after{
                        content: '';
                        height: 5px;
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        background: $light_grey;
                        @include anim;
                        @media only screen and (max-width: 991px) {
                            width: 5px;
                            height: 100%;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                    div.step{
                        @include anim;
                        background: $white;
                        position: absolute;
                        z-index: 2;
                        top: 0;
                        left: 0;
                        width: 62px;
                        padding-bottom: 56px;
                        height: 0;
                        border: 3px solid $light_grey;
                        border-radius: 50%;
                        position: relative;
                        text-align: center;
                        font-family: $special_font;
                        font-weight: 600;
                        color: $dark_grey;
                        font-size: 25px;
                        line-height: 25px;
                        cursor: pointer;
                        @media only screen and (max-width: 1500px) {
                            width: 52px;
                            padding-bottom: 46px;
                            font-size: 21px;
                            line-height: 21px;
                        }
                        @media only screen and (max-width: 991px) {
                            margin: 0 auto 0px auto;
                            width: 42px;
                            padding-bottom: 36px;
                            top: 0;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

section.slider{
    min-height: 100vh;
    width: 100%;
    position: relative;
    > ul{
        > li{
            > div.container{
                > div.row{
                    > div.col-md-12{
                        overflow: hidden;
                    }
                }
            }
            div{
                &.slider_top{
                    height: 100vh;
                    width: 100%;
                    position: relative;
                    //overflow: hidden;
                    @media only screen and (max-width: 991px) {
                        margin-top: 0 !important;
                    }
                    header{
                        position: relative;
                        z-index: 6;
                        padding-top: 20px;
                        color: $header_color;
                        @media only screen and (max-width: 991px) {
                            margin-top: 140px;
                            text-align: center;
                            padding-top: 0;
                        }
                        div.default_font{
                            padding-bottom: 50px;
                            @media only screen and (max-width: 991px) {
                                padding-bottom: 15px;
                            }
                            h1{
                                strong{
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                    > div{
                        position: absolute;
                        bottom:0;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        img{
                            max-width: 100%;
                            height: auto;
                        }
                        &.image1{
                            width: 20%;
                            left: 0;
                            margin-bottom: 24px;
                            @media only screen and (max-width: 991px) {
                                display: none;
                            }
                        }
                        &.image2{
                            right: 0;
                            width: 47%;
                            //height: 0;
                            height: 100%;
                            //padding-bottom: 47%;
                            @media only screen and (max-width: 991px) {
                                width: 80%;
                                padding-bottom: 80%;
                                left: 10%;
                            }
                            > div{
                                position: absolute;
                                @media only screen and (max-width: 991px) {
                                    display: none;
                                }
                            }
                        }
                    }
                    
                }
                &.slider_bottom{
                    background: $content_color;
                    width: 100%;
                    padding-top: 50px;
                    .container{
                        div.row{
                            margin-left: -45px;
                            margin-right: -45px;
                            div.col-md-8, div.col-md-4{
                                padding-left: 45px;
                                padding-right: 45px;
                            }
                        }
                    }
                    div{
                        &.col-md-8{
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 30px;
                            }
                        }
                        &.desc{
                            margin-bottom: 50px;
                        }
                        &.mini_gal{
                            margin-bottom: 50px;
                           > ul{
                               > li{
                                display: inline-block;
                                vertical-align: top;
                                width: 23%;
                                margin-right: 2.66%;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    margin-right: 0;
                                    margin-bottom: 15px;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                }
                                &:last-child , &:nth-child(4n){
                                    margin-right: 0;
                                }
                                .image{
                                    width: 100%;
                                    height: 0;
                                    padding-bottom: 74%;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                }
                               }
                           }
                        }
                        &.go_to_form{
                            background: $accent_color2;
                            text-align: center;
                            padding: 60px 0;
                            ul{
                                li{
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-right: 67px;
                                    div.default_font{
                                        h2{
                                            color: $accent_color;
                                        }
                                    }
                                    &:last-child{
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.slide1{
                 div.slider_top{
                   .image2{
                    .moving_element1{
                        top: 14%;
                        left: -14%;
                        width: 33%;
                        img{
                            max-width: 100%;
                            height: auto;
                        }
                    }
                    .moving_element2{
                        top: 40%;
                        left: -35%;
                        width: 33%;
                        img{
                            max-width: 100%;
                            height: auto;
                        }
                    }
                    .moving_element3{
                        top: 31%;
                        left: 37%;
                        width: 19%;
                        img{
                            max-width: 100%;
                            height: auto;
                        }
                    }
                   }
                }
            }
            &.slide2{
                div.slider_top{
                  .image2{
                   .moving_element2{
                    right: 0;
                    top: 55%;
                    z-index: 5;
                   }
                   .moving_element3{
                    top: 40%;
                    left: 36%;
                    z-index: 5;
                   }
                  }
               }
           }
           &.slide3{
            div.slider_top{
              .image2{
               .moving_element2{
                right: 27%;
                top: 17%;
                z-index: 5;
               }
              }
           }
       }
        }
    }
    .to_bottom_box{
        text-align: center;
        width: 100%;
        position: absolute;
        left: 0;
        top:85vh;
        z-index: 5;
        @media only screen and (max-width: 991px) {
            top: 80vh;
        }
        .to_bottom{
            background-repeat: no-repeat;
            background-position: center top;
            background-image: url('./../img/to_bottom_arr.png');
            font-size: 12.5px;
            font-weight: 600;
            color: $white;
            cursor: pointer;
            display: inline-block;
            padding-top: 72px;
        }
    }
}
@media only screen and (max-width: 991px) {
    .rwd_cover{
        @include fill;
        z-index: 6;
        background: $white;
        display: none;
    }
}


header.section_header{
    padding: 0px 0 0px 30px;
    margin-bottom: 45px;
    border-left: 3px solid $accent_color;
    @media only screen and (max-width: 991px) {
        padding: 0px 0 0px 15px;
        margin-bottom: 20px;
    }
    div.default_font{
        color: $header_color;
        h1{
            font-size: 25px;
            line-height: 38px;  
        }
        h1,h2{
            font-weight: 600;
            strong{
                font-weight: 600;
                color: $accent_color;
            }
        }
    }
}

footer.footer{
    div{
        &.footer_top{
            padding: 40px 0;
            border-top: 2px solid #e2ebf0;
            border-bottom: 2px solid #e2ebf0;
            @media only screen and (max-width: 991px) {
                padding: 0 0 40px 0;
            }
            ul{
                li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 70px;
                    padding-left: 40px;
                    background-repeat: no-repeat;
                    background-position: left top 6px;
                    margin-top: 30px;
                    min-height: 35px;
                    @media only screen and (max-width: 1500px) {
                        margin-right: 37px;
                        img{
                            max-width: 200px;
                            height: auto;
                            margin-top: 10px;
                        }
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-right: 27px;
                        img{
                            max-width: 150px;
                            height: auto;
                            margin-top: 20px;
                        }
                    }
                    @media only screen and (max-width: 991px) {
                        img{
                            margin-bottom: 20px;
                            margin-top: 0;
                        }
                    }
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        margin-bottom: 15px;
                        width: 100%;
                        text-align: center;
                        background-position: center top;
                        padding-left: 0;
                        padding-top: 40px;
                        margin-top: 0;
                    }
                    &:first-child{
                        margin-top: 0;
                        padding-left: 0;
                    }
                    &.phone{
                        background-image: url('./../img/phone_icon.png');
                    }
                    &.mail{
                        background-image: url('./../img/mail_icon.png');
                     }
                     &.adres{
                        background-image: url('./../img/nav_icon.png');
                     }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        &.footer_bottom{
            padding: 35px 0;
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
            ul{
                @media only screen and (max-width: 991px) {
                    padding-top: 15px;
                }
                li{
                    display: inline-block;
                    vertical-align: middle;
                    img{
                        margin-left: 24px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .text-right{
        text-align: center;
    }
}


.scaled_font{
    font-size: 3.65vw;
    line-height: 4.5vw;
    margin-bottom: 5%;
    @media only screen and (max-width: 991px) {
        font-size: 30px;
        line-height: 45px;
    }
}
